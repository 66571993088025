import React from 'react'
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import { Link } from 'gatsby'

const PrivacyPolicy = () => {
    return (
        <Layout page="Privacy Policy">
            <PageBanner
                pageTitle="Privacy Policy" 
                crumbs={[{page: "Home", url: "/"}]} 
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                                <p><i>This Privacy Policy was last updated on January 1, 2021.</i></p>
                                <blockquote className="blockquote">
                                    <p>Connection Goal ltd (”We”) are committed to protecting and respecting your privacy<br/>
                                    This policy (together with Website Terms and Conditions and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us (your “Information”).<br/>
                                    Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.<br/>
                                    For the purpose of the Data Protection Act 1998 (the Act) and the EU General Data Protection Regulation (GDPR), the data controller is Connection Goal LTD, Tallow road, Brentford, TW8 8EL<br/>
                                    Our nominated representative for the purpose of the Act is Miriam Bueno.<br/>
                                    </p>
                                </blockquote>
                                <h3>1. What Information Do We Use?</h3>
                                <p>We will collect and process the following data about you:<br/>
                                Information you give us. This is information about you that you give us by filling in forms on our site www.connectiongoal.com (our site) or by corresponding with us by phone, e-mail or otherwise. It only includes basic personal data required for you to be able to use our site, subscribe to our service, search for a product or service, place an order on our site, participate in discussion boards or other social media functions, enter a competition or promotion or survey and when you report a problem with our site. The information you give us may include your name, address, e-mail address and phone number and financial and credit card information.<br/><br/>
                                Information we collect about you. With regard to each of your visits to our site we will automatically collect the following information: technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions and operating system and platform;<br/><br/>
                                information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our site (including date and time), products you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, and any phone number used to call our customer service number.<br/><br/>
                                Information we receive from other sources. This is information we receive about you if you use any of the other websites we operate or the other services we provide. We are working closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, personal and administrative assistants). We will notify you when we receive information about you from them and the purposes for which we intend to use that information.<br/><br/>
                                COOKIES<br/><br/>
                                Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. For detailed information on the cookies we use and the purposes for which we use them see our Cookie policy.<br/>
                                </p>
                                <h3>2. How Do We Use This Information?</h3>
                                <p>We use information held about you in the following ways:<br/>
                                Information you give to us. We will use this information: to carry out our obligations arising from any contracts entered into between you and us and to provide you with the information, products and services that you request from us; to notify you about changes to our service.<br/><br/>
                                Information we collect about you. We will use this information: to administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes; to improve our site for the purposes of ensuring that content is presented in the most effective manner for you and for your computer; to allow you to participate in interactive and social media features of our service, when you choose to do so; as part of our efforts to keep our site safe and secure; to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you; to make suggestions and recommendations to you and other users of our site about goods or services that may interest you or them.<br/><br/>
                                Information we receive from other sources. We will combine this information with information you give to us and information we collect about you. We will use this information and the combined information for the purposes set out above (depending on the types of information we receive).<br/><br/>
                                WHAT WE WOULD ALSO LIKE TO DO WITH THE INFORMATION<br/><br/>
                                At Connection Goal we take your privacy seriously and will only use your Information as mentioned above.<br/><br/>
                                However from time to time we would like to inform you of our future offers and similar products. This Information is not shared with third party and you can unsubscribe at any time via phone, email or our website.<br/>
                                </p>
                                <h3>3. How Do We Share Your Information?</h3>
                                <p>You acknowledge and agree that from time to time we have the right to share your personal information with:<br/>
                                Any member of our group, which means our subsidiaries and our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006.<br/><br/>
                                Selected third parties including business partners, suppliers and sub-contractors for the performance of any contract we enter into with you; analytics and search engine providers that assist us in the improvement and optimisation of our site;<br/><br/>
                                We will disclose your personal information to third parties:<br/><br/>
                                In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets.<br/><br/>
                                If Connection Goal ltd or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.<br/><br/>
                                If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our Website Terms and Conditions and other agreements; or to protect the rights, property, or safety of Connection Goal Limited, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.<br/>
                                </p>
                                <h3>4. Where Do We Store Your Information?</h3>
                                <p>All Information we process is processed by our staff in the UK however for the purposes of IT hosting and maintenance this information is located on servers within the European Union.<br/><br/>
                                No third parties have access to your personal data unless the law allows them to do so.<br/><br/>
                                Once we have received your information, we have a Data Protection regime in place to oversee the effective and secure processing of your personal data and we will use strict procedures and security features to try to prevent unauthorised access.<br/><br/>
                                However and unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site at transmission stage; any transmission is at your own risk.<br/>
                                </p>
                                <h3>5. How Long Do We Keep Your Information For?</h3>
                                <p>We are required under UK tax law to keep your basic personal data (name, address, contact details) for a minimum of 6 years after which time it will be destroyed.<br/><br/>
                                The Information we use for marketing purposes will be kept with us until you notify us that you no longer wish to receive this information.<br/>
                                </p>
                                <h3>6. What Are Your Rights?</h3>
                                <p>If at any point you wish to know which Information we are processing about you, or if you do not wish for us to process your Information anymore or if you believe the Information we process on you is incorrect or obsolete you can request to see this Information and/or have it corrected or deleted by us by contacting hello@connectiongoal.com<br/><br/>
                                If you wish to raise a complaint on how we have handled your Information, please contact us at the email address above to have the matter investigated.<br/><br/>
                                If you are not satisfied with our response or believe we are processing your Information not in accordance with the law you have the right to complain to the Information Commissioner’s Office https://ico.org.uk/<br/>
                                </p>
                                <h3>Changes to Our Privacy Policy</h3>
                                <p>
                                Any changes we make to our privacy policy in the future will be posted on this page and notified to you by e-mail.
                                </p>        
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/opportunities">
                                                Opportunities
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/terms-of-service">
                                                Terms of Service
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/acceptable-use-policy">
                                                Acceptable Use Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default PrivacyPolicy;